<template>
    <link-button
        :to="route"
        :color="color"
        class="ma-2"
        style="border-radius: 16px; min-width: 32px; height: 32px"
    >
        {{ btnText }}
    </link-button>
</template>

<script>
import LinkButton from '@/components/table/LinkButton'
import { mapGetters } from 'vuex'
import rowRouteActionsMixins from '@/components/table/rowRouteActionsMixins'
import rowDeleteActionsMixins from '@/components/table/rowDeleteActionsMixins'

export default {
    name: 'RowButton',
    components: {
        LinkButton,
    },
    mixins: [rowRouteActionsMixins, rowDeleteActionsMixins],
    data: () => ({
        btnText: null,
        color: null,
    }),
    computed: {
        ...mapGetters({ districtId: 'getDistrictId' }),
        route() {
            const route = this.createRoute(this.params.buttonParams, this.params.data)

            return route.href
        },
    },
    mounted() {
        const { params } = this
        const text = params.value ? params.value.toString() : '0'
        this.btnText = params.buttonParams.btnText ?? text
        this.color = params.buttonParams.color ?? 'primary'
    },
}
</script>
