<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"><v-icon>fal fa-ellipsis-v</v-icon></v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="prop in routeProps"
                :key="prop.route"
                :disabled="prop.disabled"
                :to="prop.route"
                link
                style="vertical-align: middle"
            >
                <v-list-item-title>
                    <v-icon v-if="prop.icon" size="14" left color="#050F2D" style="min-width: 16px">
                        {{ prop.icon }}
                    </v-icon>
                    {{ prop.btnText }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="fileName" style="vertical-align: middle" @click="download">
                <v-list-item-title>
                    <v-icon size="14" left color="#050F2D" style="min-width: 16px"
                        >fa fa-download</v-icon
                    >
                    Download File
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                style="vertical-align: middle"
                :to="{
                    name: 'import-error-logs',
                    params: { batchId: params.data.batchId, etlStatusId: params.data.etlStatusId },
                }"
            >
                <v-list-item-title>
                    <v-icon size="14" left color="#050F2D" style="min-width: 16px"
                        >fas fa-exclamation-triangle</v-icon
                    >
                    Error logs
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import downloadFileMixin from '@/components/table/downloadFileMixin'
import { mapGetters } from 'vuex'
import rowRouteActionsMixins from '@/components/table/rowRouteActionsMixins'
import rowDeleteActionsMixins from '@/components/table/rowDeleteActionsMixins'

export default {
    name: 'LogActions',
    mixins: [downloadFileMixin, rowRouteActionsMixins, rowDeleteActionsMixins],
    computed: {
        ...mapGetters({ hasRole: 'auth/hasRole', districtId: 'getDistrictId' }),
    },
}
</script>
