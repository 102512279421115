export const saveFile = (filename, data) => {
    const blob = new Blob([data])
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.click()
    // clean up document from download.
    document.body.removeChild(link)
}
