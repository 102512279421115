import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({ hasRole: 'auth/hasRole' }),
        dialogProps: function () {
            const { data, dialogButtons } = this.params

            if (!dialogButtons) return []

            return dialogButtons.map((dialogProps) => {
                return {
                    id: data.id,
                    key: data.id,
                    disabled: !this.hasRole(dialogProps.role),
                    ...dialogProps,
                }
            })
        },
    },
}
