import getPagination from '@/helpers/ag-grid/pagination/graphql'
import store from '@/store'
import router from '@/router'

/*
 * AG Grid Data Sources for the different graphQL endpoints
 *
 * @param fetchRows
 *   FetchRows is a function that should call the vuex dispatch method for
 *   fetching rows for the table.
 *
 * @param rowData
 *   RowData should be the data from the store for that table
 *
 * @param knownTotalCount
 *   The graphql endpoints do not return total count / pages for paginated calls. If false this param
 *   will make the table show 'page # of more' instead of 'page # of 1300'
 *
 * @returns {{getRows: getRows}}
 *   returns an object containing a getRows function -- this is what AG Grid expects
 */

const fullStore = function (fetchRows = async function () {}, getterName = {}) {
    const fetch = async () => {
        await fetchRows()

        return store.getters[getterName]
    }

    return {
        getRows: async function (params) {
            const data = await fetch()

            params.success({ rowData: data, rowCount: data.length })
        },
    }
}

const partialStore = function (
    fetchRows = async function () {},
    getterNamespace = '',
    knownTotalCount = false
) {
    const fetch = async (queryPayload) => {
        await fetchRows(queryPayload)

        return {
            data: store.getters[`${getterNamespace}/getItems`],
            pagination: store.getters[`${getterNamespace}/getPagination`],
        }
    }

    return {
        getRows: async function (params) {
            const payload = getPagination(params)
            const { data, pagination } = await fetch(payload)
            const paginationProxy = params.api.paginationProxy
            const pageSize = paginationProxy ? paginationProxy.pageSize : null

            let count = knownTotalCount ? data.length : pagination.totalCount
            if (pageSize > data.length) {
                count = data.length
            }
            params.success({ rowData: data, rowCount: count })

            const query = router.currentRoute.query
            if (Object.keys(query).length === 0) {
                return
            }

            const { agCurrPage } = query

            if (agCurrPage) {
                params.api.paginationGoToPage(parseInt(agCurrPage))
            }
        },
    }
}

const serversideVuexFullStore = (fetcher, getterName, { fetchArgs }) => {
    const fetchRows = async () => {
        await store.dispatch(fetcher, fetchArgs)
    }

    return { getRows: fullStore(fetchRows, getterName).getRows }
}

export default {
    partialStore,
    fullStore,
    serversideVuexFullStore,
}
