import formatting from '@/helpers/form/formatting'

export default function (params) {
    const limit = params.api.gridOptionsWrapper.gridOptions.cacheBlockSize
    const page = params.request.startRow
    const sortModel = params.request.sortModel
    const filterModel = params.api.getFilterModel()

    const order = buildOrder(sortModel)
    const filter = buildFilter(filterModel)

    return {
        ['pagination[limit]']: limit,
        ['pagination[offset]']: page,
        ...order,
        ...filter,
    }
}

export const buildOrder = (sortModel) => {
    let order = {}
    if (sortModel.length > 0) {
        order = Object.entries(sortModel).reduce((acc, curr) => {
            acc[`order[${curr[1].colId}]`] = curr[1].sort

            return acc
        }, {})
    }

    return order
}

export const buildFilter = (filterModel) => {
    return Object.entries(filterModel).reduce((acc, curr) => {
        const filterCol = curr[0]
        switch (curr[1].filterType) {
            case 'text': {
                acc[filterCol] = curr[1].filter
                break
            }
            case 'date': {
                acc[filterCol] = formatting.isoDate(curr[1].dateFrom)
                break
            }
        }

        return acc
    }, {})
}
