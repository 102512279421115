<template>
    <v-chip class="ma-2" :color="color()"> {{ text() }} </v-chip>
</template>

<script>
export default {
    name: 'ChipCellStatus',
    methods: {
        color() {
            switch (this.params.value) {
                case 'Error':
                    return 'error'
                case 'Status':
                    return 'primary'
                default:
                    return 'primary'
            }
        },
        text() {
            return this.params.value
        },
    },
}
</script>