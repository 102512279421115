<template>
    <v-chip class="mx-2" label small :color="color.background" :text-color="color.text">
        {{ text }}
    </v-chip>
</template>

<script>
import { humanizeStr } from '@/helpers/form/formatting'

export default {
    name: 'ChipCellInstanceStatus',
    computed: {
        status() {
            // ag grid passes status in params
            // instance view/edit passes status in attrs
            return this.params?.value || this.$attrs.status
        },
        text() {
            return humanizeStr(this.status)
        },
        color() {
            switch (this.status) {
                case 'active':
                    return { background: '#49C379', text: '#05OF2D' }
                case 'archived':
                case 'notDeployed':
                    return { background: '#005673', text: '#FFFFFF' }
                case 'requestingDeployment':
                case 'requestingActivation':
                case 'requestingDeactivation':
                case 'requestingArchival':
                case 'requestingRestore':
                    return { background: '#FFBD00', text: '#050F2D' }
                case 'inactive':
                case 'failedDeployment':
                case 'failedDeactivation':
                case 'failedArchival':
                case 'failedRestore':
                    return { background: '#BF0D00', text: '#FFFFFF' }
            }

            return { background: null, text: null }
        },
    },
}
</script>