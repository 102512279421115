<template>
    <child-layout title="Import Logs Results">
        <ag-grid-server-side
            id="importLogResults"
            :server-side-datasource="serverSideDatasource"
            suppress-row-click-selection="true"
            :column-defs="columnDefs"
            :default-sort="{ startTime: 'desc' }"
        >
            <template #controls>
                <archive-download-button v-if="archivePath" :path="archivePath" />
            </template>
        </ag-grid-server-side>
    </child-layout>
</template>

<script>
import ChildLayout from '@/components/layout/ChildLayout'
import AgGridServerSide from '@/components/table/AgGridServerSide'
import LogActions from '@/views/DataExplorer/LogActions'
import FileDownload from '@/components/table/FileDownload'
import ColumnDef from '@/helpers/ag-grid/columnDef/importLog/results'
import ArchiveDownloadButton from '@/components/ArchiveDownloadButton'
import { mapActions, mapGetters } from 'vuex'
import DataSource from '@/helpers/ag-grid/datasource/graphql'

export default {
    name: 'ImportLogResults',
    components: {
        ChildLayout,
        AgGridServerSide,
        // eslint-disable-next-line vue/no-unused-components
        FileDownload,
        // eslint-disable-next-line vue/no-unused-components
        LogActions,
        ArchiveDownloadButton,
    },
    data: () => ({
        columnDefs: ColumnDef,
    }),
    computed: {
        ...mapGetters({
            fetching: 'importLogResults/getFetching',
            items: 'importLogResults/getItems',
            lea: 'getDistrictLea',
        }),
        batchId() {
            return this.$route.params.batchId
        },
        archivePath() {
            return this.items.length > 0
                ? this.items.find((f) => f.filePath && f.filePath.length > 1)?.filePath
                : null
        },
    },
    methods: {
        ...mapActions({
            fetchRows: 'importLogResults/get',
        }),
        serverSideDatasource: function () {
            const fetch = async (params) => {
                const leaId = this.lea
                await this.fetchRows({ lea: leaId, batchId: this.batchId, ...params })

                return this.items
            }

            return {
                getRows: DataSource.partialStore(fetch, 'importLogResults').getRows,
            }
        },
    },
}
</script>

<style scoped></style>
