<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"><v-icon>fal fa-ellipsis-v</v-icon></v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="item in items"
                :key="item.key"
                :disabled="item.disabled"
                :to="item.route"
                link
                style="vertical-align: middle"
            >
                <!-- dialog -->
                <component :is="item.dialog" v-if="item.dialog" :id="item.id">
                    <template #activator="{ on }">
                        <v-list-item-title v-on="on">
                            <v-icon
                                v-if="item.icon"
                                style="min-width: 16px"
                                size="14"
                                left
                                color="#050F2D"
                            >
                                {{ item.icon }}
                            </v-icon>
                            {{ item.btnText }}
                        </v-list-item-title>
                    </template>
                </component>

                <!-- route -->
                <v-list-item-title v-else>
                    <v-icon v-if="item.icon" size="14" left color="#050F2D" style="min-width: 16px">
                        {{ item.icon }}
                    </v-icon>
                    {{ item.btnText }}
                </v-list-item-title>
            </v-list-item>
            <delete-dialog
                v-if="params.deleteButton"
                :delete-action="deleteItem"
                :delete-text="deleteText"
                :title="`Delete ${deleteTitle}?`"
            >
                <template v-slot:default="{ on, attrs }">
                    <v-list-item
                        small
                        :disabled="!canDelete"
                        color="danger"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-list-item-title style="vertical-align: middle">
                            <v-icon size="14" color="#050F2D" style="min-width: 16px" left>
                                fal fa-trash
                            </v-icon>
                            Delete
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </delete-dialog>
        </v-list>
    </v-menu>
</template>

<script>
import DeleteDialog from '@/components/table/DeleteDialog'
import rowRouteActionsMixins from '@/components/table/rowRouteActionsMixins'
import rowDeleteActionsMixins from '@/components/table/rowDeleteActionsMixins'
import rowDialogActionsMixins from '@/components/table/rowDialogActionsMixins'
import SiteDialog from '@/views/Site/SiteDialog'

export default {
    name: 'RowActionButtons',
    components: {
        DeleteDialog,
        SiteDialog,
    },
    mixins: [rowRouteActionsMixins, rowDeleteActionsMixins, rowDialogActionsMixins],
    data: () => ({
        opened: false,
    }),
    computed: {
        items() {
            return [...this.routeProps, ...this.dialogProps]
        },
    },
}
</script>
