<template>
    <label class="v-label theme--light date-picker">
        <span class="label-wrap">
            {{ label }}
        </span>
        <v-menu
            v-model="dateMenuItem"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="value"
                    label=""
                    readonly
                    outlined
                    clearable
                    dense
                    v-bind="attrs"
                    hide-details="auto"
                    @click:prepend="dateMenuItem = true"
                    @click:clear="clearClick"
                    v-on="on"
                >
                    <template #prepend-inner>
                        <v-icon size="18px" class="mt-1">fal fa-calendar-alt</v-icon>
                    </template>
                </v-text-field>
            </template>
            <v-date-picker :value="value" @input="updateValue"></v-date-picker>
        </v-menu>
    </label>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        dateMenuItem: false,
    }),
    methods: {
        updateValue: function (value) {
            this.$emit('input', value)
            this.dateMenuItem = false
        },
        clearClick: function () {
            this.updateValue(null)
        },
    },
}
</script>
<style lang="scss" scoped>
.date-picker {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &::v-deep {
        .v-input {
            max-width: 100%;
            width: 400px;
        }
    }
    .label-wrap {
        width: 150px;
        line-height: 2;
        font-size: 14px;
    }
}
</style>
