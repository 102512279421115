<template>
    <div class="ag-theme-material">
        <div class="mx-1">
            <v-row class="pb-2" justify="space-between">
                <v-col class="d-flex flex-grow-0 align-center">
                    <span class="mb-4 mr-1">Show </span>
                    <drop-down
                        v-model="pageSize"
                        :items="availablePageSizes"
                        style="width: 100px"
                        @change="updatePageSize"
                        flat
                        dense
                        hide-details
                    />
                    <span class="mb-4 ml-1"> results</span>
                </v-col>
                <v-col>
                    <v-text-field
                        v-if="quickSearch"
                        v-model="customQuickSearch"
                        placeholder="Quick search"
                        outlined
                        hide-details
                        class="quick-search"
                        append-icon="fal fa-search"
                    />
                    <floating-filters
                        v-if="agGridFilterNamespace"
                        :ag-grid-filter-config="agGridFilterConfig"
                        :filter-store-namespace="agGridFilterNamespace"
                    />
                </v-col>

                <v-col>
                    <div class="d-flex justify-end">
                        <slot name="controls"></slot>
                        <v-tooltip bottom v-if="showDownloadButton">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="ml-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="btnExport"
                                    :loading="exportProcessing"
                                    icon
                                >
                                    <v-icon small>fas fa-file-download</v-icon>
                                </v-btn>
                            </template>
                            <span>Download as CSV</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="ml-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="restore"
                                    icon
                                >
                                    <v-icon small>fas fa-trash-restore</v-icon>
                                </v-btn>
                            </template>
                            <span>Restore removed columns</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="ml-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="refreshGrid"
                                    icon
                                >
                                    <v-icon small>fas fa-sync</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh grid data</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-toolbar v-if="selectedRows.length > 0 && hasBulkActions" rounded dense flat dark class="primary">
            {{ selectedRows.length }} Item(s) selected
            <v-spacer />
            <slot name="multi-select-actions" />
            <v-btn plain @click="gridApi.deselectAll()">Cancel</v-btn>
        </v-toolbar>
        <ag-grid-vue
            :grid-options="gridOptions"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            row-selection="multiple"
            enable-range-selection
            @sort-changed="sortChangedEvent"
            @filter-changed="filterChangedEvent"
            @pagination-changed="paginationChangedEvent"
            @selection-changed="onSelectionChange"
            @column-resized="onColChange"
            @column-visible="onColChange"
            @column-moved="onColChange"
            @column-pinned="onColChange"
            @model-updated="onModelUpdated"
        />
    </div>
</template>

<script>
import {AgGridVue} from '@ag-grid-community/vue'
import RowButton from '@/components/table/RowButton'
import RowActionButtons from '@/components/table/RowActionButtons'
import ChipCellNumber from '@/components/table/ChipCellNumber'
import ChipCellStatus from '@/components/table/ChipCellStatus'
import ChipCellInstanceStatus from '@/components/table/ChipCellInstanceStatus'
import ChipBoolean from '@/components/table/ChipBoolean'
import DatePickerForm from '@/components/form/DatePickerForm'
import FloatingFilters from '@/components/table/FloatingFilters'
import { SELECTED_FILTERS } from '@/store/modules/ag-grid-filters'
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'
import equal from 'deep-equal';
import DropDown from '@/components/form/DropDown.vue'

export default {
    components: {
    FloatingFilters,
    DatePickerForm,
    AgGridVue,
    RowButton,
    ChipCellNumber,
    ChipCellStatus,
    ChipCellInstanceStatus,
    ChipBoolean,
    RowActionButtons,
    DropDown
},
    props: {
        id: {
            type: String,
        },
        columnDefs: {
            type: Array,
        },
        serverSideDatasource: {
            type: Function,
        },
        setGridApi: {
            type: Function,
            default: () => {},
        },
        serverSideStoreType: {
            type: String,
            default: 'partial',
        },
        availablePageSizes: {
            type: Array,
            default: () => [10, 25, 50, 100],
        },
        defaultPageSize: {
            type: Number,
            default: 10,
        },
        agGridFilterNamespace: {
            //name of the store in the ag-grid vuex module
            type: String,
            default: '',
        },
        agGridFilterConfig: {
            type: Object,
            default: () => ({ data: {}, definition: [] }),
        },
        cacheBlockSize: {
            type: Number,
            default: 100,
            // validate against max page size
            validator: (val) => val >= 100
        },
        btnExportAction: {
            type: Function,
            default: async function () {this.gridApi.exportDataAsCsv()}
        },
        showDownloadButton: {
            type: Boolean,
            default: true, // Show the button by default
        }, 
        quickSearch: {
            type: Boolean,
            default: false,
        },
        defaultSort: {
            type: Object,
            default: () => {},
        }
    },
    data: () => ({
        gridOptions: {},
        gridApi: null,
        columnApi: null,
        defaultColDef: null,
        savedState: null,
        pageSize: null,
        debounceFilter: null,
        debounceColState: null,
        selectedRows: [],
        modelUpdated: false,
        exportProcessing: false,
        customQuickSearch: null,
        customQuickSearchDebounce: null,
    }),
    computed: {
        ...mapGetters({ preferences: 'userPreference/getPreferences' }),
        loadingFilters() {
            return this.$store.getters[`${this.agGridFilterNamespace}/loadingFilters`]
        },
        hasBulkActions() {
          return !!this.$slots['multi-select-actions']
        },
    },
    watch: {
        async customQuickSearch(v) {
            clearTimeout(this.customQuickSearchDebounce)
            this.customQuickSearchDebounce = setTimeout(async () => {
                this.gridApi.customQuickSearch = v
                this.refreshGrid()
            }, 500)
        },
    },
    beforeMount() {
        this.gridOptions = {
            pagination: true,
            domLayout: 'autoHeight',
            rowModelType: 'serverSide',
            serverSideStoreType: this.serverSideStoreType,
            paginationPageSize: this.defaultPageSize,
            cacheBlockSize: this.cacheBlockSize, // always fetch 100 blocks at a time.
            sideBar: {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                        },
                    },
                ],
            },
            suppressCopyRowsToClipboard: true,
        }
        this.defaultColDef = {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['equals'],
                suppressAndOrCondition: true,
            },
            floatingFilter: true,
        }
    },
    async mounted() {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi
        this.savedState = this.gridColumnApi?.getColumnState()
        // make it possible to pass api back to parent.
        this.setGridApi(this.gridApi)

        // set table
        await this.setTableState()
        this.gridApi.setServerSideDatasource(this.serverSideDatasource())
    },
    created() {
        this.$store.subscribe((mutation) => {
            if (mutation.type === `${this.agGridFilterNamespace}/${SELECTED_FILTERS}`) {
                Object.keys(mutation.payload).forEach((key) => {
                    if (!this.gridApi) {
                        return
                    }
                    // Because this subscribes to all created grids we need to check if the grid has been destroyed
                    // We dont want to fire off filter changes on destroyed grids (for a number of reasons)
                    if (this.gridApi.destroyCalled) {
                        return
                    }
                    // This subscription is only used for the old floating filters. in the floating filters, the
                    // filter value is set on the itemId if the item is an object.
                    if (typeof mutation.payload[key] === 'object') {
                        this.filterChange(key, mutation.payload[key]?.itemId)
                    } else {
                        this.filterChange(key, mutation.payload[key])
                    }
                })
            }
        })
    },
    methods: {
        ...mapActions({
            getUserPreferences: 'userPreference/get',
            saveColState: 'userPreference/saveColState',
            savePageSize: 'userPreference/savePageSize',
        }),
        async btnExport() {
            this.exportProcessing = true;
            await this.btnExportAction()
            this.exportProcessing = false;
        },
        restore() {
            this.gridColumnApi.applyColumnState({ applyOrder: true, state: this.savedState })
        },
        refreshGrid() {
            this.gridApi.refreshServerSideStore({ purge: true })
        },
        updatePageSize(newPageSize) {
            const pageSize = Number(newPageSize)
            this.gridApi.paginationSetPageSize(pageSize)
            this.savePageSize({ tblId: this.id, pageSize })
        },
        onModelUpdated(e) {
            // used to ignore ag grid events before
            // table has fully initialized
            this.modelUpdated = e.animate
        },
        filterChange(filterField, filterValue) {
            const filterInstance = this.gridApi.getFilterInstance(filterField)
            if (!filterInstance) {
                return
            }

            clearTimeout(this.debounceFilter)
            this.debounceFilter = setTimeout(() => {
                filterInstance.setValueFromFloatingFilter(filterValue)
                filterInstance.applyModel()
                this.gridApi.onFilterChanged()
            }, 500)
        },
        onSelectionChange() {
            this.selectedRows = this.gridApi.getSelectedRows()
        },
        async setTableState() {
            // get preferences
            if (!this.preferences) {
                await this.getUserPreferences()
            }

            const { columnStates, pageSizes } = this.preferences
            const { query: {agFilters, agSort, agPageSize} } = router.currentRoute

            // page size
            const pageSize = agPageSize || pageSizes[this.id]
            if (pageSize) {
                this.gridApi.paginationSetPageSize(parseInt(pageSize));
            }

            // filters
            const filters = Object.entries(agFilters || {});
            filters.forEach(([key, value]) => this.filterChange(key, value))

            // combine column state from preferences and column state from URL
            const colState = columnStates[this.id] || []
            Object.entries(agSort || this.defaultSort || {}).forEach(([key, value]) => {
                const col = colState.find(col => col.colId === key)
                if (col) {
                    col.sort = value
                } else {
                    colState.push({colId: key, sort: value})
                }
            })

            this.gridColumnApi.applyColumnState({ applyOrder: true, state: colState })
        },
        async sortChangedEvent(event) {
            if (!this.modelUpdated) return

            const colState = event.columnApi.getColumnState()

            if (this.id) {
                this.saveColState({ tblId: this.id, colState })
            }

            const sortState = colState.reduce((acc, curr) => {
                if (curr.sort === null) {
                    return acc;
                }
                acc[curr.colId] = curr.sort;

                return acc;
            }, {})

            await this.saveQueryToUrl({agSort: sortState})
        },
        async paginationChangedEvent(event) {
            if (!this.modelUpdated) return

            const {paginationProxy} = event.api
            const agPageSize = paginationProxy.pageSize;
            this.pageSize = agPageSize;
            let queryParams = {agPageSize: agPageSize.toString()};
            const agCurrPage = paginationProxy.currentPage.toString();

            if (event.newPage) {
                queryParams = {...queryParams, agCurrPage};
            }

            await this.saveQueryToUrl(queryParams)
        },
        async filterChangedEvent(event) {
            const filters = event.api.getFilterModel();
            const queryParams = Object.keys(filters).reduce((acc, curr) => {
                acc[curr] = filters[curr].filter;

                return acc;
            }, {})

            await this.saveQueryToUrl({agFilters: queryParams})
        },
        async saveQueryToUrl(queryParams) {
            const currQuery = router.currentRoute.query;
            //check if the params in queryParams are different than the current query params
            //this is to avoid a NavigationDuplicated error / warn from vue router.
            const diffParams = Object.keys(queryParams).filter(key => {
                //using deep-equal because these are sometimes objects
                if (!equal(queryParams[key], currQuery[key])) {
                    return key;
                }
            });

            if (diffParams.length === 0) {
                return;
            }

            const query = {...currQuery, ...queryParams};

            await router.replace({query});
        },
        onColChange(e) {
            if (!this.id || e.source === 'api') return

            clearTimeout(this.debounceColState)
            this.debounceColState = setTimeout(
                () => this.saveColState({
                    tblId: this.id,
                    colState: this.gridColumnApi.getColumnState()
                }), 2000
            )
        }
    },
}
</script>

<style scoped lang="scss">
.quick-search {
    width: 250px;

    &::v-deep {
        .v-icon {
            font-size: 16px;
        }
    }
}
</style>
