import api from '@/api/api'

export default {
    async download(params) {
        try {
            return await api.get('/api/file-download', { params })
        } catch (err) {
            throw 'Error downloading file'
        }
    },
}
