<template>
    <a href="#" @click="download">{{ params.data.fileName }}</a>
</template>

<script>
import downloadFileMixin from '@/components/table/downloadFileMixin'

export default {
    name: 'FileDownload',
    mixins: [downloadFileMixin],
}
</script>
