import { mapGetters } from 'vuex'

const rowRouteActionsMixins = {
    computed: {
        ...mapGetters({
            districtId: 'getDistrictId',
            hasRole: 'auth/hasRole',
        }),
        routeProps: function () {
            const { data, linkButtons } = this.params

            if (!linkButtons) return []

            return linkButtons.map((linkProps) => {
                const hasRole = linkProps.role ? this.hasRole(linkProps.role) : true
                const route = this.createRoute(linkProps, data)

                return {
                    key: route.href,
                    route: route.href,
                    disabled: route.href === null || !hasRole,
                    ...linkProps,
                }
            })
        },
    },
    methods: {
        createRoute: function (params, data) {
            const defaults = { districtId: this.districtId }
            let missingParam = false

            const routeParams = params.routerLinkIds.reduce((acc, curr) => {
                if (!data[curr]) {
                    missingParam = true
                }
                acc[curr] = data[curr]

                return acc
            }, defaults)

            //If any of the params are null or undefined we want the route to be disabled (return null)
            if (missingParam) {
                return {
                    href: null,
                }
            }

            return this.$router.resolve({
                params: routeParams,
                name: params.routeName,
                query: params.query,
            })
        },
    },
}

export default rowRouteActionsMixins
