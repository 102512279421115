<template>
    <v-btn icon download :href="link" target="_blank">
        <v-icon small>fa-solid fa-file-archive</v-icon>
    </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ArchiveDownloadButton',
    props: {
        path: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            lea: 'getDistrictLea',
        }),
        link() {
            return `/api/admin-sftp/?path=${this.path}&lea=${this.lea}`
        },
    },
}
</script>

<style></style>
