import { errorSnackbar, infoSnackbar, successSnackbar } from '@/helpers/snackbar'
import api from '@/api/file-download'
import { mapGetters } from 'vuex'
import { saveFile } from '@/helpers/file'

const downloadFileMixin = {
    computed: {
        ...mapGetters({
            lea: 'getDistrictLea',
        }),
        filePath() {
            let path = this.params.data.filePath
            if (!path) {
                return '/'
            }

            //force linux paths and ensure we have a trailing slash
            path = path.replace(/\\/g, '/')
            path += path.endsWith('/') ? '' : '/'

            return path
        },
        fileName() {
            return this.params.data.fileName
        },
    },
    methods: {
        async download(e) {
            e.preventDefault()

            infoSnackbar({
                display: true,
                message: 'Download Started',
                subtext: `File "${this.fileName}" download has been initiated`,
            })

            let response = null
            let subtext = `File "${this.fileName}" has been successfully downloaded!`
            //filePath should have a trailing slash
            const path = `${this.filePath}${this.fileName}`

            try {
                response = await api.download({
                    lea: this.lea,
                    filepath: path,
                })
            } catch (e) {
                const message = 'Download Failed'
                subtext = `File "${this.fileName}" is not currently available for download!`
                await errorSnackbar({ message, subtext })

                return
            }
            if (response.data.errors) {
                const message = 'Download Failed'
                subtext = `File "${this.fileName}" is not currently available for download!`
                await errorSnackbar({ message, subtext })

                return
            }
            const content = Buffer.from(response.data.data.fileDownload.content, 'base64')
            saveFile(this.fileName, content)

            await successSnackbar({ subtext })
        },
    },
}

export default downloadFileMixin
