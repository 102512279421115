<template>
    <v-card rounded outlined class="mt-2">
        <v-card-title v-if="showHeader" class="py-2 px-4">
            <h4 v-if="title" class="child-layout__title text-primary">
                {{ title }}
            </h4>
            <v-spacer />
            <slot name="actions" />
        </v-card-title>
        <v-divider v-if="showHeader" />
        <v-sheet class="py-4 px-4">
            <slot />
        </v-sheet>
    </v-card>
</template>

<script>
export default {
    name: 'ChildLayout',
    props: {
        title: {
            type: String,
            default: null,
        },
    },
    computed: {
        showHeader() {
            return this.$slots['actions'] || this.title
        },
    },
}
</script>
<style lang="scss">
.child-layout__title {
    font-size: 16px;
    margin-bottom: 0;
}

.child-layout__label {
    color: rgba(5, 15, 45, 0.7);
    // This is accomplished using line-height on the design comp.
    // I'm really not sure how to translate it, this seems correct relatively.
    margin: 8px auto auto auto;
    &:first-child {
        margin: auto auto auto auto;
    }
    font-size: 12px;
}

.child-layout__info {
    margin: auto;
    font-size: 14px;
    &:last-child {
        margin: inherit inherit 30px inherit;
    }
    &:empty::before {
        content: '--';
    }
}
</style>
