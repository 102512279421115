<template>
    <v-chip class="ma-2" :color="color"> {{ text }} </v-chip>
</template>

<script>
import { numberWithCommas } from '@/helpers/ag-grid/cellFormats'

export default {
    name: 'ChipCellNumber',
    computed: {
        columnValue() {
            return this.params.data[this.params.colDef.field]
        },
        text() {
            return numberWithCommas(this.columnValue)
        },
        color() {
            return this.params.color
        },
    },
}
</script>