var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}])},[_c('v-list',[_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.key,staticStyle:{"vertical-align":"middle"},attrs:{"disabled":item.disabled,"to":item.route,"link":""}},[(item.dialog)?_c(item.dialog,{tag:"component",attrs:{"id":item.id},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[(item.icon)?_c('v-icon',{staticStyle:{"min-width":"16px"},attrs:{"size":"14","left":"","color":"#050F2D"}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.btnText)+" ")],1)]}}],null,true)}):_c('v-list-item-title',[(item.icon)?_c('v-icon',{staticStyle:{"min-width":"16px"},attrs:{"size":"14","left":"","color":"#050F2D"}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.btnText)+" ")],1)],1)}),(_vm.params.deleteButton)?_c('delete-dialog',{attrs:{"delete-action":_vm.deleteItem,"delete-text":_vm.deleteText,"title":("Delete " + _vm.deleteTitle + "?")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"small":"","disabled":!_vm.canDelete,"color":"danger"}},'v-list-item',attrs,false),on),[_c('v-list-item-title',{staticStyle:{"vertical-align":"middle"}},[_c('v-icon',{staticStyle:{"min-width":"16px"},attrs:{"size":"14","color":"#050F2D","left":""}},[_vm._v(" fal fa-trash ")]),_vm._v(" Delete ")],1)],1)]}}],null,false,2409143721)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }