import { DateTime } from 'luxon'

export const dateString = (date, time) => {
    return new Date(date + ' ' + time).toLocaleTimeString()
}

export const numberWithCommas = (number) => {
    if (!number) {
        return 0
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const displayTime = (timeString) => {
    if (timeString) {
        return new DateTime.fromISO(timeString).setZone('est').toFormat('hh:mma')
    }
}

export const displayDateOnly = (dateString) => {
    if (dateString) {
        return new DateTime.fromISO(dateString).toFormat('MM/dd/yyyy')
    }
}
