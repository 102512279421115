<template>
    <base-dialog
        v-model="opened"
        :title="title"
        max-width="100%"
        confirm-text="Save"
        :confirm-action="onSave"
        :disable-confirm="!form.valid"
        :show-back-action="!isEdit"
        :back-action="() => (opened = false)"
        scrollable
        @close="$emit('close')"
    >
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs" />
        </template>

        <template #content>
            <v-card-text>
                <v-container fluid>
                    <extended-form
                        ref="form"
                        :form-ready="form.ready"
                        :form-data="form.data"
                        :disabled="getFetching || getSaving"
                        :show-submit="false"
                        @formValid="(valid) => (form.valid = valid)"
                    >
                        <v-row justify="space-around" class="ma-6">
                            <v-col cols="10" md="5">
                                <h5>Site Details</h5>
                                <text-field :form="form" field-key="schoolName" required />
                                <text-field
                                    :form="form"
                                    field-key="schoolAbbreviation"
                                    label="School Abbreviated Name"
                                />
                                <h5 class="pt-6">Site Identifiers</h5>
                                <text-field
                                    :form="form"
                                    field-key="schoolCode"
                                    label="SIS Site Id"
                                    :disabled="isEdit"
                                    required
                                />
                                <text-field
                                    :form="form"
                                    field-key="stateId"
                                    label="State School ID"
                                />
                                <text-field
                                    :form="form"
                                    field-key="ncesSchoolCode"
                                    label="NCES School ID"
                                    :disabled="isEdit"
                                    required
                                />
                                <h5 class="pt-6">Site Information</h5>
                                <drop-down
                                    :form="form"
                                    field-key="schoolType"
                                    label="School Type"
                                    item-text="name"
                                    item-value="name"
                                    :items="getSchoolTypes"
                                    hide-details="auto"
                                />
                                <drop-down
                                    :form="form"
                                    field-key="lowGrade"
                                    label="Low Grade Level"
                                    hide-details="auto"
                                    :items="gradeLevels"
                                />
                                <drop-down
                                    :form="form"
                                    field-key="highGrade"
                                    label="High Grade Level"
                                    hide-details="auto"
                                    :items="gradeLevels"
                                />
                                <date-picker-form
                                    v-model="form.data.schoolEstablishedDate"
                                    label="Open Date"
                                    hide-details="auto"
                                />
                                <date-picker-form
                                    v-model="form.data.schoolClosedDate"
                                    label="Closed Date"
                                ></date-picker-form>
                                <drop-down
                                    :form="form"
                                    field-key="postSecondarySiteIndicator"
                                    label="Post-Secondary Site"
                                    :items="['', 'Y', 'N']"
                                />
                                <h5 class="pt-6">Principal</h5>
                                <text-field
                                    :form="form"
                                    field-key="principalFirstName"
                                    label="First Name"
                                />
                                <text-field
                                    :form="form"
                                    field-key="principalLastName"
                                    label="Last Name"
                                />
                            </v-col>
                            <v-col cols="10" md="5">
                                <h5>Site Address</h5>
                                <text-field :form="form" field-key="address" hide-details="auto" />
                                <text-field :form="form" field-key="city" hide-details="auto" />
                                <drop-down
                                    :form="form"
                                    field-key="state"
                                    label="State"
                                    :items="getStates"
                                    item-text="name"
                                    item-value="abbr"
                                    required
                                    hide-details="auto"
                                />
                                <text-field
                                    :form="form"
                                    field-key="zipCode"
                                    label="Zip"
                                    hide-details="auto"
                                />
                                <text-field :form="form" field-key="county" hide-details="auto" />
                                <text-field :form="form" field-key="phoneNumber" label="Phone" />
                                <h5 class="pt-6">Additional Data</h5>
                                <text-field
                                    :form="form"
                                    field-key="stateBedsCode"
                                    label="NYS BEDS Code"
                                />
                                <text-field :form="form" field-key="skedulaDBN" label="NYC DBN" />
                                <text-field :form="form" field-key="cSENumber" label="CSE Number" />
                                <text-field :form="form" field-key="cEEB" label="CEEB" />
                                <text-field
                                    :form="form"
                                    field-key="originalCharterIndicator"
                                    label="Original Charter Indicator"
                                />
                                <text-field :form="form" field-key="schoolManager" />
                                <text-field :form="form" field-key="areaCode" />
                                <h5 class="pt-6">Options</h5>
                                <v-switch v-model="form.data.blockImport">
                                    <template v-slot:label>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    style="font-size: 14px"
                                                    v-on="on"
                                                >
                                                    Block Imports
                                                </span>
                                            </template>
                                            Block this site from future imports. When this is
                                            active, imports will no longer affect data associated
                                            with this site.
                                        </v-tooltip>
                                    </template>
                                </v-switch>
                                <v-switch v-model="form.data.doNotUseMasterSchedule">
                                    <template v-slot:label>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    style="font-size: 14px"
                                                    v-on="on"
                                                >
                                                    Do Not Use Master Schedule
                                                </span>
                                            </template>
                                            Toggles if the "use master schedule" flag should be used
                                            for Aeries imports
                                        </v-tooltip>
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row>
                    </extended-form>
                </v-container>
            </v-card-text>
        </template>
    </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DatePickerForm from '@/components/form/DatePickerForm'
import TextField from '@/components/form/TextField'
import formatting from '@/helpers/form/formatting'
import { between } from '@/helpers/form/validation'
import ExtendedForm from '@/components/form/ExtendedForm'
import { formSnackbar } from '@/helpers/snackbar'
import DropDown from '@/components/form/DropDown.vue'
import BaseDialog from '@/components/modal/BaseDialog'

export default {
    name: 'SiteDialog',
    components: {
        ExtendedForm,
        DatePickerForm,
        TextField,
        DropDown,
        BaseDialog,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
    },
    data: () => ({
        form: {
            valid: false,
            ready: false,
            errors: [],
            rules: {
                schoolCode: [(v) => !!v || 'School code is required', (v) => between(v, 0, 50)],
                ncesSchoolCode: [(v) => !!v || 'Site ID is required', (v) => between(v, 0, 10)],
                schoolName: [(v) => !!v || 'Site Name is required'],
                state: [(v) => !!v || 'State is required', (v) => between(v, 0, 50)],
                highGrade: [(v) => between(v, 0, 2)],
                lowGrade: [(v) => between(v, 0, 2)],
                address: [(v) => between(v, 0, 155)],
                city: [(v) => between(v, 0, 155)],
                zipCode: [(v) => between(v, 0, 50)],
                phoneNumber: [(v) => between(v, 0, 50)],
                schoolType: [(v) => between(v, 0, 100)],
                originalCharterIndicator: [(v) => between(v, 0, 1)],
                skedulaDBN: [(v) => between(v, 0, 6)],
                stateBedsCode: [(v) => between(v, 0, 50)],
                cSENumber: [(v) => between(v, 0, 50)],
                cEEB: [(v) => between(v, 0, 100)],
                principalFirstName: [(v) => between(v, 0, 100)],
                principalLastName: [(v) => between(v, 0, 100)],
            },
            data: {
                schoolCode: null,
                stateId: null,
                ncesSchoolCode: null,
                schoolName: null,
                schoolAbbreviation: null,

                address: null,
                city: null,
                state: null,
                zipCode: null,
                county: null,

                schoolType: null,
                lowGrade: null,
                highGrade: null,
                schoolEstablishedDate: null,
                schoolClosedDate: null,
                postSecondarySiteIndicator: null,
                principalFirstName: null,
                principalLastName: null,
                phoneNumber: null,

                stateBedsCode: null,
                skedulaDBN: null,
                cSENumber: null,
                cEEB: null,
                originalCharterIndicator: null,
                schoolManager: null,
                areaCode: null,

                blockImport: false,
                doNotUseMasterSchedule: false,
            },
        },
        dateFields: ['schoolEstablishedDate', 'schoolClosedDate'],
        gradeLevels: [
            '1',
            '2',
            '3',
            '4',
            '4K',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            'AD',
            'AT',
            'EC',
            'G',
            'IN',
            'JK',
            'K4',
            'KG',
            'P',
            'P3',
            'P4',
            'PK',
            'SE',
            'ST',
            'TK',
        ],
        opened: false,
    }),
    computed: {
        ...mapGetters({
            getStates: 'usStates/getStates',
            getSchoolTypes: 'schoolTypes/getSchoolTypes',
            hasRole: 'auth/hasRole',
            getErrors: 'site/getErrors',
            getFetching: 'site/getFetching',
            getSaving: 'site/getSaving',
            getItemsById: 'site/getItemsById',
            lea: 'getDistrictLea',
            districtId: 'getDistrictId',
        }),
        title() {
            return this.isEdit ? 'Edit Site' : 'Add Site'
        },
        isEdit() {
            return !!this.id
        },
    },
    watch: {
        async opened(opened) {
            // only load edit form when dialog opens
            if (!opened || !this.isEdit) return

            let site = this.getItemsById[this.id]
            if (!site) {
                await this.getSite({ id: this.id })
                site = this.getItemsById[this.id]
            }

            const formData = {}
            Object.keys(this.form.data).forEach((key) => {
                formData[key] = site[key]
                if (site[key] && this.dateFields.includes(key)) {
                    formData[key] = new Date(site[key]).toISOString().substr(0, 10)
                }
            })

            this.setFormData(formData)
        },
    },
    methods: {
        ...mapActions({
            getSite: 'site/get',
            postSite: 'site/post',
            patchSite: 'site/patch',
        }),
        async onSave() {
            const { id, lea, districtId, isEdit, form, dateFields } = this

            const data = { districtId, ...form.data }
            dateFields.forEach((field) => {
                data[field] = formatting.isoDate(data[field])
            })

            if (isEdit) {
                await this.patchSite({ id, lea, data })
            } else {
                await this.postSite({ lea, data })
                this.$refs.form.reset()
            }

            await formSnackbar({
                type: 'Site',
                identifier: data.schoolName,
                isEdit,
            })
        },
        setFormData(formData) {
            this.form.data = formData
            this.form.ready = true
        },
    },
}
</script>
<style scoped>
h5 {
    color: rgba(43, 57, 99, 1);
}
</style>
