var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ag-theme-material"},[_c('div',{staticClass:"mx-1"},[_c('v-row',{staticClass:"pb-2",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex flex-grow-0 align-center"},[_c('span',{staticClass:"mb-4 mr-1"},[_vm._v("Show ")]),_c('drop-down',{staticStyle:{"width":"100px"},attrs:{"items":_vm.availablePageSizes,"flat":"","dense":"","hide-details":""},on:{"change":_vm.updatePageSize},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}}),_c('span',{staticClass:"mb-4 ml-1"},[_vm._v(" results")])],1),_c('v-col',[(_vm.quickSearch)?_c('v-text-field',{staticClass:"quick-search",attrs:{"placeholder":"Quick search","outlined":"","hide-details":"","append-icon":"fal fa-search"},model:{value:(_vm.customQuickSearch),callback:function ($$v) {_vm.customQuickSearch=$$v},expression:"customQuickSearch"}}):_vm._e(),(_vm.agGridFilterNamespace)?_c('floating-filters',{attrs:{"ag-grid-filter-config":_vm.agGridFilterConfig,"filter-store-namespace":_vm.agGridFilterNamespace}}):_vm._e()],1),_c('v-col',[_c('div',{staticClass:"d-flex justify-end"},[_vm._t("controls"),(_vm.showDownloadButton)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"loading":_vm.exportProcessing,"icon":""},on:{"click":_vm.btnExport}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file-download")])],1)]}}],null,false,730883777)},[_c('span',[_vm._v("Download as CSV")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":_vm.restore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash-restore")])],1)]}}])},[_c('span',[_vm._v("Restore removed columns")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":_vm.refreshGrid}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-sync")])],1)]}}])},[_c('span',[_vm._v("Refresh grid data")])])],2)])],1)],1),(_vm.selectedRows.length > 0 && _vm.hasBulkActions)?_c('v-toolbar',{staticClass:"primary",attrs:{"rounded":"","dense":"","flat":"","dark":""}},[_vm._v(" "+_vm._s(_vm.selectedRows.length)+" Item(s) selected "),_c('v-spacer'),_vm._t("multi-select-actions"),_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){return _vm.gridApi.deselectAll()}}},[_vm._v("Cancel")])],2):_vm._e(),_c('ag-grid-vue',{attrs:{"grid-options":_vm.gridOptions,"column-defs":_vm.columnDefs,"default-col-def":_vm.defaultColDef,"row-selection":"multiple","enable-range-selection":""},on:{"sort-changed":_vm.sortChangedEvent,"filter-changed":_vm.filterChangedEvent,"pagination-changed":_vm.paginationChangedEvent,"selection-changed":_vm.onSelectionChange,"column-resized":_vm.onColChange,"column-visible":_vm.onColChange,"column-moved":_vm.onColChange,"column-pinned":_vm.onColChange,"model-updated":_vm.onModelUpdated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }