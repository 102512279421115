<template>
    <v-btn v-bind="$attrs" small :color="color">
        <template v-for="(_, slot) of $slots" v-slot:[slot]><slot :name="slot" /></template>
    </v-btn>
</template>

<script>
export default {
    name: 'LinkButton',
    props: {
        color: {
            type: String,
            default: 'primary',
        },
    },
}
</script>
