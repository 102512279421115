<template>
    <div v-if="!loadingFilters" class="d-flex flex-row flex-wrap">
        <div
            v-for="(filter, index) in getDefinition"
            :key="index"
            class="pr-1"
            :style="`width: ${filter.width}`"
        >
            <v-text-field
                v-if="filter.filterType === 'text-field'"
                v-model="filterModel[filter.filterField]"
                filled
                autocomplete="off"
                :label="filter.label"
                @keyup="filterChange(filter)"
            />
            <label class="v-label theme--light auto-complete-field">
                <v-autocomplete
                    v-model="filterModel[filter.filterField]"
                    class="v-label theme--light auto-complete-field mb-0"
                    flat
                    return-object
                    clearable
                    dense
                    autocomplete="off"
                    item-text="itemText"
                    item-value="itemId"
                    outlined
                    :items="getFilterData[filter.filterField]"
                    hide-details
                    @change="(e) => filterChange(filter)"
                />
            </label>
            <date-picker-form
                v-if="filter.filterType === 'dateRange'"
                v-model="filterModel[filter.filterField]"
                :label="filter.label"
                @input="filterChange(filter)"
            />
        </div>
    </div>
</template>

<script>
import DatePickerForm from '@/components/form/DatePickerForm'
import { SELECTED_FILTERS, SET_FILTER_CONFIG } from '@/store/modules/ag-grid-filters'

export default {
    components: {
        DatePickerForm,
    },
    props: {
        filterStoreNamespace: {
            type: String,
            default: '',
        },
        agGridFilterConfig: {
            type: Object,
            default() {
                return { data: {}, definition: [] }
            },
        },
    },
    data: () => ({
        filterModel: {},
        columnDef: [],
        theFilterData: {},
    }),
    computed: {
        getDefinition() {
            return this.$store.getters[`${this.filterStoreNamespace}/getDefinition`]
        },
        getFilterData() {
            return this.$store.getters[`${this.filterStoreNamespace}/getFilterData`]
        },
        loadingFilters() {
            return this.$store.getters[`${this.filterStoreNamespace}/loadingFilters`]
        },
    },
    watch: {
        getDefinition() {
            this.changeFilters()
        },
        getFilterData() {
            this.changeFilters()
        },
        agGridFilterConfig() {
            this.$store.dispatch(
                `${this.filterStoreNamespace}/${SET_FILTER_CONFIG}`,
                this.agGridFilterConfig
            )
        },
    },
    mounted() {
        this.$store.dispatch(
            `${this.filterStoreNamespace}/${SET_FILTER_CONFIG}`,
            this.agGridFilterConfig
        )

        this.changeFilters()
    },
    methods: {
        changeFilters() {
            this.getDefinition.forEach((def) => {
                if (!def.defaultValue) {
                    return
                }

                // loop through all values available in set and see if provided default is available.
                const hasValue = this.getFilterData[def.filterField].find((item) => {
                    // if the default value is an object check for the item Id in the list of available values.
                    if ('object' === typeof def.defaultValue) {
                        return item.itemId === def.defaultValue.itemId
                    }
                    return item === def.defaultValue
                })

                if (!hasValue) {
                    return
                }

                this.filterModel = {
                    [def.filterField]: hasValue,
                }
                this.filterChange(def)
            })
        },
        filterChange(filter) {
            if (filter.filterType === 'text-field') {
                clearTimeout(this.debounceFilter)
                this.debounceFilter = setTimeout(() => {
                    this.dispatchSelected(filter)
                }, 500)
                return
            } else {
                this.dispatchSelected(filter)
            }
        },
        dispatchSelected(filter) {
            // I don't fully understand what this is doing. It appears to save the filter instance.
            // since value isn't stored in filter it may just be forcing a re-render? I'm not even sure.
            this.$store.dispatch(`${this.filterStoreNamespace}/${SELECTED_FILTERS}`, {
                [filter.filterField]: this.filterModel[filter.filterField],
            })
        },
    },
}
</script>

<style lang="scss" scoped>
/** 
    This is just copied from the autocomplete component. It should get updated to not be coupled
    to the old form system. I don't want to tackle that here as it seems like a bigger task.
 */
.auto-complete-field {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &::v-deep {
        .v-input {
            max-width: 100%;
            width: 400px;
            font-size: 14px;
        }
        .v-icon {
            font-size: 14px;
        }
    }
    .label-wrap {
        width: 150px;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}
</style>
